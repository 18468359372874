import { React, useState, useEffect } from "react";
import {
  Container,
  ContainerFluid,
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { getAllInvoices } from "../../../src/helper/service";
import close from "../../images/dashboard/modal-close.svg";
import Loader from '../../Loader';
import Papa from 'papaparse';
import ConfirmationModal from './ConfirmationModal';

const InvoicesComponent = () => {
  const [transactions, setTransactions] = useState([]);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getAllInvoices()
      .then((data) => {
        if (data.status === 200 && data.result?.success) {
          setTransactions([...data.result?.invoices]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    getAllInvoices(query)
      .then((data) => {
        if (data.status === 200 && data.result?.success) {
          setTransactions([...data.result?.invoices]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleExport = () => {
    const csvData = transactions.map((transaction) => ({
      ID: transaction?.payment.id,
      Customer: transaction?.payment.metadata?.email,
      "Date/Time": transaction?.payment.metadata?.date,
      "Amount ($)": `${transaction?.payment.amount / 100
        } ${transaction?.payment.currency.toUpperCase()}`,
      "Payment Method": `${transaction?.payment.payment_method_types.length > 0
          ? transaction?.payment.payment_method_types
          : "Unknown"
        }`,
      Status: transaction?.payment.status,
      "View Receipt": transaction.receiptUrl,
    }));
    if (csvData.length === 0) {
      setMessage("No data to export.");
      setWarningModal(true);
      setModalType("warning");
      setWarningModalTitle("Warning!");
      return;
    }

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "invoices.csv";
    link.click();
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={warningModalTitle}
          message={message}
          onConfirm={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
          }}
          onCancel={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
          }}
        />
      )}
      <Container fluid style={{ marginTop: "3em" }}>
        <Row>
          <h3>Transaction Details</h3>
          <Form onSubmit={(e) => handleSearch(e)}>
            <Row>
              <Col md={4}>
                <Form.Group className="d-flex">
                  <Form.Label className="mt-2 mr-3">Email:</Form.Label>
                  <Form.Control
                    id="search-text"
                    type="text"
                    placeholder="Enter complete Email"
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ height: "2.5rem" }}
                  />
                </Form.Group>
              </Col>
              <Col md={2} className="align-self-end">
                <Form.Group>
                  <button
                    type="submit"
                    className="custom-button-design submit-btn"
                  >
                    Search
                  </button>
                </Form.Group>
              </Col>
              <Col md={2} className="align-self-end">
                <Form.Group>
                  <button
                    onClick={handleExport}
                    variant="secondary"
                    className="custom-button-design submit-btn"
                  >
                    Export
                  </button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <div className="memberShip-list">
            <div className="table-responsive mt-3">
              <Table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Customer</th>
                    <th>Date/Time</th>
                    <th>Amount ($)</th>
                    <th>Payment Method</th>
                    <th>Status</th>
                    <th>Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction?.payment.id}</td>
                      <td>{transaction?.payment.metadata?.email}</td>
                      <td>{transaction?.payment.metadata?.date}</td>
                      <td>{`${transaction?.payment.amount / 100
                        } ${transaction?.payment.currency.toUpperCase()}`}</td>
                      <td>{`${transaction?.payment.payment_method_types.length > 0
                          ? transaction?.payment.payment_method_types
                          : "Unknown"
                        }`}</td>
                      <td>{transaction?.payment.status}</td>
                      <td>
                        <div className="dropdown dropleft">
                          <span
                            id="member-option"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </span>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="member-option"
                            style={{ zIndex: 5, right: 0 }}
                          >
                            <button
                              type="button"
                              className="close ml-0 pl-0 member-action-close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">
                                <img src={close} alt="close" />
                              </span>
                            </button>
                            <button className="dropdown-item">
                              <a
                                className="dropdown-item"
                                target="_blank"
                                href={transaction.receiptUrl}
                              >
                                View
                              </a>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default InvoicesComponent;
